import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";

class Contact extends Component {

  render() {

    return (
      <div>
        <Breadcrumb title={'Contact Us'}/>

        {/*Forget Password section*/}
        <section className=" contact-page section-b-space">
          <div className="container">
            <div className="row section-b-space">
              <div className="col-lg-7 map">
                <iframe
                    title="map-location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48772.613196288396!2d44.47987951816206!3d40.18039646553831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abda94d56d6a7%3A0xdf438bc9c991f857!2sDalma%20Garden%20Mall!5e0!3m2!1sen!2s!4v1576099058360!5m2!1sen!2s"
                    allowFullScreen/>
              </div>
              <div className="col-lg-5">
                <div className="contact-right">
                  <ul>
                    <li>
                      <div className="contact-icon">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/phone.png`}
                             alt="Generic placeholder"/>
                        <h6>Contact Us</h6>
                      </div>
                      <div className="media-body">
                        <p>+37441098015</p>
                      </div>
                    </li>
                    <li>
                      <div className="contact-icon">
                        <i className="fa fa-map-marker" aria-hidden="true"/>
                        <h6>Address</h6>
                      </div>
                      <div className="media-body">
                        <p>Dalma Garden Mall`</p>
                        <p>2-րդ հարկ</p>
                      </div>
                    </li>
                    <li>
                      <div className="contact-icon">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/email.png`}
                             alt="Generic placeholder"/>
                        <h6>Address</h6>
                      </div>
                      <div className="media-body">
                        <p>customer.service@lightaffect.am</p>
                        <p>info@lightaffect.am</p>
                      </div>
                    </li>
                    <li>
                      <div className="contact-icon">
                        <i className="fa fa-fax" aria-hidden="true"/>
                        <h6>Fax</h6>
                      </div>
                      <div className="media-body">
                        <p>customer.service@lightaffect.am</p>
                        <p>info@lightaffect.am</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <form className="theme-form">
                  <div className="form-row">
                    <div className="col-md-6">
                      <label htmlFor="name">First Name</label>
                      <input type="text" className="form-control" id="name"
                             placeholder="Enter Your name" required=""/>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Last Name</label>
                      <input type="text" className="form-control" id="last-name"
                             placeholder="Email" required=""/>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="review">Phone number</label>
                      <input type="text" className="form-control" id="review"
                             placeholder="Enter your number" required=""/>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Email</label>
                      <input type="text" className="form-control" id="email" placeholder="Email"
                             required=""/>
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="review">Write Your Message</label>
                      <textarea className="form-control" placeholder="Write Your Message"
                                id="exampleFormControlTextarea1" rows="6"></textarea>
                    </div>
                    <div className="col-md-12">
                      <button className="btn btn-solid" type="submit">Send Your Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

      </div>
    )
  }
}

export default Contact
