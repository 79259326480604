import React, {Component} from 'react';

import Breadcrumb from "../common/breadcrumb";

class termsAndConditions extends Component {

  render() {


    return (
      <div>
        <Breadcrumb title={'Terms and conditions'}/>
        {/*about section*/}
        <section className="about-page  section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h4>1. General</h4>
                <p>
                  The following terms and conditions apply to all orders placed by the customer (hereinafter referred to
                  as ”you”) with MarkeTex LTD , hereinafter referred to as “Light Affect”, "us" or ”we”, at the Light
                  Affect website, mobile website, or mobile application, or via telephone (jointly referred to as
                  ”lightaffect.am”).
                  By using lightaffect.am and/or placing an order, you agree to be bound by the terms and conditions
                  set out herein (the ”Terms”). Please make sure you have read and understood the Terms before placing
                  your order.
                  Only persons 18 years or older, who are not under guardianship, and are not acting in a capacity of a
                  company, can place an order.
                  The inclusion of any products or services on lightaffect.am at a particular time does not imply or
                  warrant that these products or services will be available at any time. We reserve the right to
                  discontinue any product at any time.
                  We reserve the right to amend these Terms from time to time without prior notice to you. The version
                  of the Terms that will apply to your order will be those on lightaffect.am at the time you place your
                  order.
                  Light Affect wants to provide the best possible online experience. To make this possible we need to
                  ensure that our services runs like a clockwork. You understand and agree not to (i) post, transmit,
                  redistribute, upload, or promote any communications or content that could harm or negatively impact
                  our business, products or services; (ii) act in a manner or employ any device that restricts, impairs,
                  interferes or inhibits any other user from using or enjoying the lightaffect.am site, or which
                  impacts the security of the site.
                </p>

                <h4>2. Prices and delivery charges</h4>
                <p>
                  The prices displayed at lightaffect. include a delivery charge.
                  The delivery charge for each order will vary, regarding the weight of each product presented on the
                  website. The cost for each delivery method is clearly indicated during the check-out process. Prices
                  in store, catalogue and online may vary.
                </p>

                <h4>3. Ordering/conclusion of contract</h4>
                <p>
                  The ordering options available to you are via lightaffect.am. Please contact us through our <a
                  href="/"> Customer Service</a> E-mail for further information.
                  Once you have placed your order, if you have supplied us with your email address, you will receive an
                  email confirmation. If for any reason we are unable to fulfill your order, we will let you know at the
                  earliest opportunity. If, for any reason, alternative arrangements are necessary, an Light Affect
                  representative will contact you to settle the refund.
                </p>

                <h4>4. Delivery</h4>
                <p>
                  Light Affect exercises the utmost diligence in accepting and processing orders and will endeavour to
                  deliver your order to any address you write down.
                  Light Affect endeavours to dispatch orders in the fastest possible time and in the order in which they
                  are placed. We aim to deliver within 6 to 15 days following confirmation of an order, unless otherwise
                  agreed. Please allow for an extra 1-2 working days for deliveries to be packed and sent to the post.
                  Though we aim to deliver within the communicated time frame, delivery may take longer due to
                  unexpected events. In the unlikely event the delivery time exceeds 30 days, you may cancel your order.
                  Before you place your order, you will be informed of the expected delivery details. Once you have
                  placed your order, if you have supplied us with your email address, you will receive an email
                  confirmation with your expected delivery details.
                  If your order does not arrive by the scheduled date, please contact <a href="/">Customer
                  Service</a> through E-mail.
                </p>

                <h4>5. Withdrawal of Order</h4>
                <p>
                  You may cancel or return your order from the date you place the order until 28 days after receipt of
                  the ordered items. If you have been charged for the items, you will receive a refund which will
                  include Light Affect's standard delivery costs. Should you choose to keep certain items and only
                  partially cancel your order, the delivery cost will not be refunded. If you wish to cancel or return
                  your order, please contact <a href="/">Customer Service</a>. We will
                  provide a refund attempting to use the same method used to make the payment as soon as possible and at
                  the latest within 14 days of receiving your notice of cancellation, provided we have received the
                  items or evidence of you having sent back the items. Another payment method may be used for the
                  refund, provided that you consent to such change; in any case this will not generate any additional
                  charges. If the item you have received is defective, please notify Light Affect promptly and return
                  the item in accordance with section 6 (Returns) below. Light Affect will, for any products deemed
                  defective, provide a full refund. The provisions set forth herein do not limit any applicable
                  statutory rights.
                </p>

                <h4>6. Returns</h4>
                <p>
                  Light Affect guarantees a full refund of the returned items in accordance with section 5 (Withdrawal
                  of Order), provided that all items are returned in the same condition they were in upon your receipt.
                  This means the items should not have been damaged, soiled, washed, altered or worn (other than to try
                  the item on) and that any labels or tags should be intact.
                  You can bring the item(s) you wish to return to any Light Affect store, provided that you bring your
                  delivery note to the store. You will be refunded by the same payment method you used to make your
                  purchase, in accordance with section 5.3 above. You can use your refunds for exchanges or new
                  purchases directly in store if the purchase was made by credit card. Faulty items may also be returned
                  to any Light Affect store.
                </p>

                <h4>7. Payment methods</h4>
                <p>
                  You can pay for your goods in various ways as set out below.
                </p>
                <h4>
                  Payment before delivery
                </h4>
                <p>
                  Credit card or debit card
                  You can enter your payment details at the time you place your order using a valid credit or debit card
                  (VISA or Mastercard). The applicable amount will immediately be reserved on your card but will not be
                  debited until the goods are dispatched. Light Affect reserves the right to check the validity of the
                  credit or debit card, its credit status in relation to the order value and whether the address data of
                  the purchaser is correct. We may refuse orders depending on the result of these checks.
                  PayPal
                  When paying by PayPal, Light Affect reserves the right to check the validity of the PayPal account,
                  that there are enough funds to cover the purchase sum and validate the billing address details of the
                  purchaser. The applicable amount will immediately be reserved on your PayPal account but will not be
                  debited until the goods are dispatched from the warehouse.
                </p>
                <h4>8. Our Liability</h4>
                <p>
                  Nothing in these Terms shall exclude or limit Light Affect's liability with regard to any matter for
                  which it would be unlawful for us to limit or exclude our liability.
                  Light Affect accepts liability for death or personal injury caused by our negligence or that of our
                  employees and agents. We do not seek to exclude liability for fraudulent misrepresentation by us or
                  our employees or agents. If we breach these Terms, we shall only be liable for losses which are a
                  reasonably foreseeable consequence of such breach.

                  Light Affect is not responsible for indirect losses which are a side effect of the main loss or
                  damage, for example loss of profits or loss of opportunity; or for failure to deliver the goods or to
                  meet any of our other obligations under these Terms where such failure is due to an event that is
                  beyond our reasonable control, which includes but is not limited to fire, flood, storm, riot, civil
                  disturbance, war, nuclear accident and terrorist activity.
                  Our maximum liability to you for any loss or damage arising in connection with your order on
                  lightaffect.am shall be limited to the total price of your order.
                </p>
                <h4>9. Colours and measurements</h4>
                <p>
                  We make all reasonable efforts to accurately display the attributes of our products, including
                  composition and colours. The colour you see will depend on your computer system, and we cannot
                  guarantee that your computer will accurately display such colours.
                  An item’s measurements given on lightaffect.am are only approximate values to give you a better
                  understanding of the model or fit of that specific item, and not a definite guarantee of the actual
                  measurements of the item you receive. The final measurements of an item may vary depending on the
                  material used in its production.
                </p>
                <h4>10. Guarantee</h4>
                <p>
                  If there are defects in the goods you have purchased, Light Affect abides by all statutory guarantee
                  regulations. If you have a complaint regarding obvious material or manufacturing faults in goods that
                  we have supplied, please let us know by returning the goods to us without delay.
                </p>
                <h4>11. Assignment</h4>
                <p>
                  Light Affect reserves the right to assign or pledge to third parties any claim(s) for payment
                  including any payment instalments which have arisen in connection with the delivery of goods.
                </p>
                <h4>12. Severance</h4>
                <p>
                  If any part of these Terms is disallowed or found to be ineffective by any court or regulator, the
                  other Terms shall continue to apply.
                </p>
                <h4>13. Ownership of rights</h4>
                <p>
                  All intellectual property rights, such as trademarks and copyrights at lightaffect.am remain with
                  MarkeTex LTD and its subsidiaries or licensors. Any use of lightaffect.am or its contents,
                  including copying or storing such content in whole or part, other than for your own personal,
                  non-commercial use, is prohibited without the permission of Light Affect.
                </p>
                <h4>14. Company Information</h4>
                <p>MarkeTex LTD</p>
                <p>Registered office: Papazyan 22a apt 14</p>
                <p>Yerevan, Armenia</p>
              </div>
            </div>
          </div>
        </section>

      </div>
    )
  }
}

export default termsAndConditions
