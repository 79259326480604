import React, {Component} from 'react';

import Breadcrumb from "../common/breadcrumb";

class privacyPolicy extends Component {

  render() {
    return (
      <div>
        <Breadcrumb title={'Privacy policy'}/>
        {/*about section*/}
        <section className="about-page  section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <p>
                  This Privacy policy only concerns Light Affect customers and users of our online services.
                </p>
                <p>
                  Light Affect ensures that your privacy is protected when using our services. This Privacy Policy
                  (“policy”) applies to information that is collected by Light Affect. This policy applies only to
                  information collected at lightaffect.am, any location where the policy is posted (collectively, the
                  “Sites”). Any changes we have made to this Privacy policy can be found by visiting us online. When we
                  do make changes, we will update the “Last Updated” date below. Where changes are viewed as more
                  material, we may attempt to contact you or we will post a notice on this website that we have made
                  changes to this policy.
                </p>
                <p>
                  The Armenian company - Light Affect is responsible for your personal data. Your personal data is
                  stored and maintained in Armenia and processed within the Light Affect group. By using the Site, you
                  consent to the transfer of your data overseas and across borders, and from your country or
                  jurisdiction to other countries or jurisdictions around the world. The laws governing data in your
                  home country may differ from those in the countries to which data is transferred. By accessing and
                  using the Site, you consent to the transfer of your data in this manner.
                </p>
                <h4>
                  How do we use your data?
                </h4>
                <p>
                  In providing your personal data you consent to Light Affect using the data collected in order to meet
                  our commitments to you and to provide you with the service you expect. We need your personal data for
                  the following purposes:
                </p>
                <p>
                  • To process your orders (e.g. your name, address, date of birth and bank details)
                </p>
                <p>
                  • To be able to send text message notifications of delivery status (e.g. your E-mail)
                </p>
                <p>
                  • To be able to send you marketing offers such as newsletters and our catalogues (e.g. your email
                  address, your name and your postal address)
                </p>
                <p>
                  • To be able to contact you in the event of any problem with the delivery of your items (e.g.
                  telephone number, E-mail, address)
                </p>
                <p>
                  • To enable us to answer your queries and to inform you of new or changed services (e.g. your email
                  address)
                </p>
                <p>
                  • To be able to analyse your personal data to provide you with relevant marketing offers and
                  information (e.g. name, buying habits)
                </p>
                <p>
                  • To be able to validate that your are of legal age for shopping online (e.g. date of birth)
                </p>
                <p>
                  We will only keep your data for as long as necessary to carry out our services to you or as long as we
                  are required by law. After this your personal data will be deleted. Non-personal data is used as
                  described above and in other ways as permitted by applicable laws, including combining non-personal
                  data with personal data.
                </p>
                <h4>
                  What are your rights?
                </h4>
                <p>
                  You have the right to request information about the personal data we hold on you. If your data is
                  incorrect, incomplete or irrelevant you can ask to have the information corrected or removed.
                  Annually, you also have the right to request written documentation, free of charge, on the personal
                  information we have on you on our account files. To request this document please write in to Light
                  Affect’s Customer Service E-mail.
                </p>
                <h4>
                  Who has access to the data?
                </h4>
                <p>
                  We do not sell your information to third parties. We do, however, share data with third parties when
                  necessary to fulfill a transaction, complete a service, for administrative purposes, or when required
                  by law. Any data that is forwarded to third parties is used to meet Light Affect’s commitments to you.
                  Light Affect may also supply your personal data to organizations such as credit reference or debt
                  collection agencies for the purposes of credit checks, identity checks, monitoring credit rating and
                  debt collection. Additionally, we will share your data if such sharing is required by law or to
                  protect against potential or suspected fraud.
                </p>
                <h4>
                  How do we protect your data?
                </h4>
                <p>
                  No data transmissions over the Internet can be guaranteed to be 100% secure. Consequently, we cannot
                  ensure or warrant the security of any information you transmit to us and you understand that any
                  information that you transfer to us is done at your own risk. That said, once we receive your
                  transmission, we have technical and organizational measures in place to help protect your data from
                  loss, manipulation, unauthorised access, etc. We continually adapt our security measures in line with
                  technological progress and developments.
                </p>
                <h4>
                  Minors
                </h4>
                <p>
                  We do not collect any personal data directly from individuals under the age of 13. If we discover that
                  any such information is in our possession, we will delete it.
                </p>
                <h4>
                  What data do we collect?
                </h4>
                <p>
                  This policy applies only to information collected on the Site. We collect two types of information
                  from visitors to the Site: (1) Personal data and (2) Non-personal data.
                  “Personal data” is information that identifies you personally, such as your name, address, telephone
                  number, email address, and sometimes your Internet Protocol (IP) address.
                  “Non-personal data” can be technical in nature. It does not identify you personally. Examples of
                  non-personal data include the following:
                </p>
                <p>
                  • Demographic Information -- "Demographic Information" may be your gender, age, zip code, geolocation
                  data and interests, which you voluntarily provide to us on and through the Sites. We use this
                  information to provide you with personalized services and to analyze trends to ensure the information
                  provided by the Sites meet your needs. Please note that we also consider aggregated information, which
                  is not personally identifiable, to be non-personal data.
                </p>
                <p>
                  The above list provides an example of the non-personal data that is collected via the Sites
                </p>
                <h4>
                  E-mail Opt-out
                </h4>
                <p>
                  We communicate with users who subscribe to our services on a regular basis via email. For example, we
                  may use your email address to confirm your request, to send you notice of payments, to send you
                  information about changes to our products and services, and to send notices and other disclosures as
                  required by law. Generally, users cannot opt-out of these communications, but they will be primarily
                  informational in nature rather than promotional.
                </p>
                <p>
                  However, we provide you the opportunity to exercise an opt-out choice if you do not want to receive
                  other types of communication from us, such as emails or updates from us regarding new services and
                  products offered on the Sites. The opt-out choice may be exercised by ticking or un-ticking the
                  appropriate box if such checkbox is available at the points where personal data is collected or by
                  contacting us. We will process your unsubscribe as soon as possible, but please be aware that in some
                  circumstances you may receive a few more messages until the unsubscribe is processed. You also may
                  opt-out of receiving such emails by clicking on the "unsubscribe" link within the text of the email.
                </p>
                <h4>
                  Copyright
                </h4>
                <p>
                  Light Affect. All rights reserved. All materials contained within this website are protected by
                  copyright belonging to Light Affect.
                </p>
                <h4>
                  International Transfer of Data
                </h4>
                <p>
                  You understand that the controller of personal data submitted through the Site may be contacted at the
                  information below:
                </p>
                <h4>
                  Controller of personal data
                </h4>
                <p>
                  MarkeTex LTD
                </p>
                <p>
                  Papazyan 22a apt 14
                </p>
                <p>
                  Yerevan, Armenia
                </p>
                <p>
                  Telephone: +37496416336
                </p>
                <p>
                  E-mail: <a href="mailto:customer.service@lightaffect.am">customer.service@lightaffect.am</a>
                </p>
                <p>
                  Last updated: 04.02.2020
                </p>

              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default privacyPolicy
